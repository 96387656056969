import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('@/Main.vue'),
        },
        {
            path: '/zh-master-steering',
            component: () => import('@/Main.vue'),
        },
        {
            path: '/zh-flexray-spy',
            component: () => import('@/FlexRaySpy'),
        },
        {
            path: '/blog',
            component: () => import('@/Blog'),
        },
        {
            path: '/blog/:slug',
            component: () => import('@/Blog'),
        },
        {
            path: '/contact-us',
            component: () => import('@/ContactUs'),
        },
        {
            path: '/demo',
            component: () => import('@/Demo.vue'),
        },
    ],
})
