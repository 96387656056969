<template>
  <router-view v-if="!isLoading" />
  <v-main
          v-else
          class="splash"
  >
    <v-app>
      <div style="max-width: 250px; margin: auto;">
        <v-img src="zhMasterSteering.png" />
        <br>
        Loading...
        <v-progress-linear
                indeterminate
                color="cyan"
        ></v-progress-linear>
      </div>
    </v-app>
  </v-main>
</template>

<script>
    export default {
        name: 'App',
        mounted () {
            setTimeout(() => {
                this.isLoading = false
            }, 300)
        },
        data () {
            return {
                isLoading: true,
            }
        },
    }
</script>

<style>
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .splash {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
