import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    language: 'English', // default language
    snackbarMessage: '',
    snackbarOpen: false,
    snackbarColor: 'red',
  },
  actions: {

  },
})
